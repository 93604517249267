import {
  Row,
  Col,
  Select,
  message,
  Button,
  Form,
  InputNumber,
  Layout,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { NavLink, Prompt } from 'react-router-dom';
import { MedicalClaimService } from '../../services/api';
import Uploader from '../components/uploader';

import { LeftOutlined } from '@ant-design/icons';
import { Text, Input, DatePicker, FormInput } from '../../components';
import FormInputNew from '../../components/FormInputNew';
import FileUploader from '../../components/FileUploader';
import { useForm } from 'antd/lib/form/Form';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { parseRequestFormData } from '../../utils/parseRequestFormData';
const { Content } = Layout;

const MedicalClaimRequest = (props) => {
  const [form] = useForm();

  const [medicalClaimType, setMedicalClaimType] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [amountRemain, setAmountRemain] = useState(0);
  const [medicalInvoicePictureFile, setMedicalInvoicePictureFile] =
    useState(null);
  const [medicalCertificatePictureFile, setMedicalCertificatePictureFile] =
    useState(null);

  const [isChanged, setIsChanged] = useState(false);
  const history = useHistory();

  useEffect(() => {
    fetchMedicalClaimAmountRemain(moment().year());
    fetchMedicalClaimType();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      medicalInvoicePicture: medicalInvoicePictureFile,
    });
  }, [medicalInvoicePictureFile]);

  useEffect(() => {
    form.setFieldsValue({
      medicalCertificatePicture: medicalCertificatePictureFile,
    });
  }, [medicalCertificatePictureFile]);

  const fetchMedicalClaimAmountRemain = (year) => {
    const param = {
      year: year,
    };
    MedicalClaimService.getMedicalClaimAmountRemain(
      param,
      ({ data }) => {
        setAmountRemain(data.amountRemain);
      },
      (response) => {
        setIsLoading(false);
        if (response) message.error(`Error: ${response.data.message}`);
      },
    );
  };

  const fetchMedicalClaimType = () => {
    MedicalClaimService.getMedicalClaimType(
      ({ data }) => {
        setMedicalClaimType(data);
      },
      (response) => {
        setIsLoading(false);
        if (response) message.error(`Error: ${response.data.message}`);
      },
    );
  };

  const onFinishMedicalClaimRequest = (value) => {
    setIsLoading(true);
    delete value.medicalInvoicePicture;
    delete value.medicalCertificatePicture;

    const formData = parseRequestFormData(value, [
      ...(medicalCertificatePictureFile ? [medicalCertificatePictureFile] : []),
      ...(medicalInvoicePictureFile ? [medicalInvoicePictureFile] : []),
    ]);

    MedicalClaimService.addMedicalClaimRequest(
      formData,
      ({ data }) => {
        setIsLoading(false);
        setIsChanged(false);
        message.success('Medical claim request has been created successfully.');
        setTimeout(() => {
          history.replace('/claim', { tabKey: '1' });
        }, 1000);
      },
      (response) => {
        setIsLoading(false);
        if (response)
          message.error(
            `Failed to add medical claim request: ${response.data.message}`,
          );
      },
    );
  };

  return (
    <div style={{ width: 'auto' }}>
      <Prompt
        when={isChanged}
        message="You have unsaved changes. Are you sure you want to leave?"
      />
      <Layout style={{ minHeight: 'calc(100vh - 64px)' }}>
        <Content
          style={{
            margin: '32px 20px 20px 20px',
            overflow: 'initial',
          }}
        >
          <Form
            style={{ width: '100%' }}
            form={form}
            onFinish={onFinishMedicalClaimRequest}
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Row
                  gutter={[16, 16]}
                  align="middle"
                  style={{ height: '100%' }}
                  wrap={false}
                >
                  <Col flex>
                    <NavLink
                      to={{ pathname: '/claim', state: { tabKey: '1' } }}
                      form="user-form"
                    >
                      <LeftOutlined className="text-normal" />
                    </NavLink>
                  </Col>
                  <Col
                    flex
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <Text h4 className="text-normal">
                      Request Medical Claim
                    </Text>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row className="card-container" gutter={[16, 16]}>
                  <Col span={24}>
                    <Text sub2 className="text-normal">
                      General Information
                    </Text>
                  </Col>
                  <Col span={24}>
                    <Row gutter={[16, 16]}>
                      <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <FormInput
                          title="Treatment Date"
                          required
                          component={
                            <Form.Item
                              style={{ width: '100%', margin: '0' }}
                              name="effectiveDate"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input treatment date.',
                                },
                              ]}
                            >
                              <DatePicker
                                format="DD/MM/YYYY"
                                disabledDate={(current) =>
                                  current > moment().endOf('year')
                                }
                                onChange={(date) => {
                                  fetchMedicalClaimAmountRemain(date.year());
                                  setIsChanged(true);
                                }}
                              />
                            </Form.Item>
                          }
                        />
                      </Col>
                      <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <FormInput
                          title="Invoice / Receipt Number"
                          required
                          component={
                            <Form.Item
                              style={{ width: '100%', margin: '0' }}
                              name="referenceNumber"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    'Please input invoice/receipt number.',
                                },
                              ]}
                            >
                              <Input
                                placeholder="Invoice / receipt number"
                                onChange={() => {
                                  setIsChanged(true);
                                }}
                              />
                            </Form.Item>
                          }
                        />
                      </Col>
                      <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <FormInput
                          title="Type"
                          required
                          component={
                            <Form.Item
                              style={{ width: '100%', margin: '0' }}
                              name="medicalClaimTypeId"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select medical claim type.',
                                },
                              ]}
                            >
                              <Select
                                placeholder="Medical claim type"
                                onChange={() => {
                                  setIsChanged(true);
                                }}
                              >
                                {medicalClaimType.map((record, index) => (
                                  <Select.Option value={record.id}>
                                    {record.name}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          }
                        />
                      </Col>
                      <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <Row gutter={[16, 16]}>
                          <Col span={24}>
                            <FormInput
                              title="Amount"
                              required
                              component={
                                <Form.Item
                                  style={{ width: '100%', margin: '0' }}
                                  name="amount"
                                  rules={[
                                    {
                                      validator: (_, value) => {
                                        if (!value) {
                                          return Promise.reject(
                                            new Error('Please input amount.'),
                                          );
                                        } else if (
                                          `${value}`.split('.')[1]?.length > 2
                                        ) {
                                          return Promise.reject(
                                            new Error(
                                              'Please input amount of 2 decimals.',
                                            ),
                                          );
                                        } else if (value <= 0) {
                                          return Promise.reject(
                                            new Error(
                                              'Amount must be positive',
                                            ),
                                          );
                                        } else if (value > amountRemain) {
                                          return Promise.reject(
                                            new Error(
                                              'The amount must be less than the remaining balance.',
                                            ),
                                          );
                                        }

                                        return Promise.resolve();
                                      },
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    onKeyDown={(event) => {
                                      const allowedKeys = [
                                        'Backspace',
                                        'Delete',
                                        'ArrowLeft',
                                        'ArrowRight',
                                        'Tab',
                                      ];

                                      if (allowedKeys.includes(event.key)) {
                                        return;
                                      }

                                      if (!/[0-9.]/.test(event.key)) {
                                        event.preventDefault();
                                      }

                                      // Prevent more than one decimal point
                                      if (
                                        event.key === '.' &&
                                        event.target.value.includes('.')
                                      ) {
                                        event.preventDefault();
                                      }
                                    }}
                                    onPaste={(event) => {
                                      if (!/[0-9.]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                    placeholder="Amount"
                                    precision={2}
                                    style={{ width: '100%' }}
                                    onChange={() => {
                                      setIsChanged(true);
                                    }}
                                  />
                                </Form.Item>
                              }
                            />
                          </Col>
                          <Col span={24}>
                            <Text small12 className="text-primary">
                              {`You have a remaining balance of
                      ${
                        amountRemain
                          ? amountRemain.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : '0.00'
                      }
                      Baht.`}
                            </Text>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row className="card-container" gutter={[16, 16]}>
                  <Col span={24}>
                    <Text sub2 className="text-normal">
                      Medical Detail
                    </Text>
                  </Col>
                  <Col span={24}>
                    <Row gutter={[16, 16]}>
                      <Col span={24}>
                        <FormInput
                          title="Hospital & Reason"
                          required
                          component={
                            <Form.Item
                              style={{ width: '100%', margin: '0' }}
                              name="reason"
                              rules={[
                                {
                                  validator: (_, value) => {
                                    const trimmedValue = value?.trim();

                                    if (!trimmedValue) {
                                      return Promise.reject(
                                        new Error(
                                          'Please input hospital & reason.',
                                        ),
                                      );
                                    }

                                    return Promise.resolve();
                                  },
                                },
                              ]}
                            >
                              <Input.TextArea
                                placeholder="Enter Hospital & Reason"
                                maxLength={255}
                                rows={5}
                                onChange={() => {
                                  setIsChanged(true);
                                }}
                              />
                            </Form.Item>
                          }
                        />
                      </Col>
                      <Col span={24}>
                        <FormInputNew
                          title="Medical Invoice"
                          required
                          component={
                            <Form.Item
                              style={{ width: '100%', margin: '0' }}
                              name="medicalInvoicePicture"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please upload medical invoice.',
                                },
                              ]}
                            >
                              <FileUploader
                                fileSizeLimit={5 * 1024 * 1024}
                                fileType={
                                  'application/pdf, image/png, image/jpg, image/jpeg'
                                }
                                file={medicalInvoicePictureFile}
                                setFile={setMedicalInvoicePictureFile}
                                fieldName={'medicalInvoicePictureFile'}
                              />
                            </Form.Item>
                          }
                        />
                      </Col>
                      <Col span={24}>
                        <FormInputNew
                          title={'Medical Certificate'}
                          required
                          component={
                            <Form.Item
                              style={{ width: '100%', margin: '0' }}
                              name="medicalCertificatePicture"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please upload medical certificate.',
                                },
                              ]}
                            >
                              <FileUploader
                                fileSizeLimit={5 * 1024 * 1024}
                                fileType={
                                  'application/pdf, image/png, image/jpg, image/jpeg'
                                }
                                file={medicalCertificatePictureFile}
                                setFile={setMedicalCertificatePictureFile}
                                fieldName={'medicalCertificatePictureFile'}
                              />
                            </Form.Item>
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={[8, 8]} justify="end" align="middle">
                  <Col flex>
                    <NavLink
                      to={{ pathname: '/claim', state: { tabKey: '1' } }}
                      form="user-form"
                    >
                      <Button width="96px" className="button-outlined">
                        Cancel
                      </Button>
                    </NavLink>
                  </Col>
                  <Col flex>
                    <Button
                      type="primary"
                      width="96px"
                      className="button-primary"
                      loading={isLoading}
                      onClick={() => form.submit()}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Content>
      </Layout>
    </div>
  );
};

export default MedicalClaimRequest;
