import {
  InboxOutlined,
  LeftOutlined,
  SettingOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Button, Col, Form, Row, Spin, Upload, message, Layout } from 'antd';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { SalaryService } from '../../services/api';

import { Select, FormInput } from '../../components';
import { Text } from '../../components';
import { useForm } from 'antd/lib/form/Form';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import { FeatureFlags } from '../../components/context/FeatureFlagContext';

const { Content } = Layout;

const SalaryUploadPage = () => {
  const [fileList, setFileList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const [options, setOptions] = useState(['All']);
  const [selectedOption, setSelectedOption] = useState('All');
  const [file, setFile] = useState();

  const { features } = useContext(FeatureFlags);

  const [form] = useForm();

  const beforeUpload = (file) => {
    setFileList([file]);
    setOptions(['All']);
    setSelectedOption('All');
  };

  const customRequest = ({ file, onSuccess, onError }) => {
    const formData = new FormData();
    formData.append('file', file);
    setIsUpload(true);
    setFile(file);
    SalaryService.uploadSalaryExcel(
      formData,
      ({ data }) => {
        setIsUpload(false);
        setOptions(['All', ...data]);
        setSelectedOption('All');
        onSuccess('Upload successfully');
      },
      (response) => {
        if (response) message.error(`Error: ${response.data.message}`);
        setIsUpload(false);
        setOptions(['All']);
        setSelectedOption('All');
        setFile(null);
        onError(response);
      },
    );
  };

  const handleChange = ({ file }) => {
    setFileList(file.status === 'removed' ? [] : [file]);
  };

  const onRemoveFile = () => {
    setOptions(['All']);
    setSelectedOption('All');
    setFile(null);
  };

  const onFinishSalaryUpload = (value) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('month', value.month + 1);
    formData.append('year', value.year);
    formData.append('empCode', selectedOption.split(':')[0]);
    setIsLoading(true);
    SalaryService.addExcelRecord(
      formData,
      ({ data }) => {
        setFileList([]);
        setIsLoading(false);
        setOptions(['All']);
        setSelectedOption('All');
        message.success('Payslip has been uploaded successfully.');
      },
      (response) => {
        setIsLoading(false);
        if (response) message.error(`Error: ${response.data.message}`);
      },
    );
  };

  const monthList = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const defaultMonth = moment().month();
  const defaultYear = moment().year();
  return (
    <div style={{ width: 'auto' }}>
      <Spin spinning={isLoading}>
        <Layout style={{ minHeight: 'calc(100vh - 64px)' }}>
          <Content
            style={{
              margin: '32px 20px 0 20px',
              overflow: 'initial',
            }}
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Row gutter={[16, 16]}>
                  <Col flex>
                    <NavLink to={{ pathname: '/payslip' }} form="user-form">
                      <LeftOutlined className="text-normal" />
                    </NavLink>
                  </Col>
                  <Col flex>
                    <Text h4 className="text-normal">
                      Pay Slip
                    </Text>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                {features?.payslipUpload ? (
                  <>
                    <Form form={form} onFinish={onFinishSalaryUpload}>
                      <Row className="card-container" gutter={[16, 16]}>
                        <Col sm={{ span: 24 }} md={{ span: 12 }}>
                          <Row gutter={[16, 16]}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                              <FormInput
                                title="EmpCode-Name"
                                required
                                component={
                                  <Select
                                    style={{
                                      width: '100%',
                                    }}
                                    disabled={options.length === 1}
                                    value={selectedOption}
                                    onChange={(value) => {
                                      setSelectedOption(value);
                                    }}
                                  >
                                    {options.map((option) => (
                                      <Select.Option value={option}>
                                        {option}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                }
                              />
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                              <FormInput
                                title="Year"
                                required
                                component={
                                  <Form.Item
                                    style={{ width: '100%', margin: 0 }}
                                    name="year"
                                    initialValue={defaultYear}
                                  >
                                    <Select defaultValue={defaultYear}>
                                      <Select.Option value={defaultYear - 1}>
                                        {defaultYear - 1}
                                      </Select.Option>
                                      <Select.Option value={defaultYear}>
                                        {defaultYear}
                                      </Select.Option>
                                      <Select.Option value={defaultYear + 1}>
                                        {defaultYear + 1}
                                      </Select.Option>
                                    </Select>
                                  </Form.Item>
                                }
                              />
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                              <FormInput
                                title="Month"
                                required
                                component={
                                  <Form.Item
                                    style={{ width: '100%', margin: 0 }}
                                    name="month"
                                    initialValue={defaultMonth}
                                  >
                                    <Select
                                      style={{
                                        width: '100%',
                                      }}
                                      defaultValue={defaultMonth}
                                    >
                                      {monthList.map((value, index) => (
                                        <Select.Option value={index}>
                                          {value}
                                        </Select.Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                }
                              />
                            </Col>
                          </Row>
                        </Col>

                        <Col sm={{ span: 24 }} md={{ span: 12 }}>
                          <Form.Item style={{ margin: 0 }}>
                            <Upload.Dragger
                              style={{
                                height: '100%',
                                minHeight: '144px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                              maxCount={1}
                              fileList={fileList}
                              onChange={handleChange}
                              beforeUpload={beforeUpload}
                              onRemove={onRemoveFile}
                              customRequest={customRequest}
                              disabled={isUpload}
                            >
                              <InboxOutlined
                                className="text-secondary-red"
                                style={{ fontSize: '24px' }}
                              />
                              <p>
                                <Text small12 className="text-normal">
                                  Click or drag file to this area to upload
                                </Text>
                              </p>
                            </Upload.Dragger>
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Row justify="end" gutter={[8, 8]}>
                            {features?.payslipEditSettings && (
                              <Col>
                                <NavLink to="/payslip/setting">
                                  <Button
                                    width="96px"
                                    className="button-outlined"
                                    icon={<SettingOutlined />}
                                  >
                                    Settings
                                  </Button>
                                </NavLink>
                              </Col>
                            )}
                            <Col>
                              <Button
                                loading={isLoading}
                                type="primary"
                                width="96px"
                                className="button-primary"
                                onClick={() => form.submit()}
                                icon={<UploadOutlined />}
                              >
                                Upload
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  </>
                ) : (
                  <Text sub4 className="text-normal">
                    You don't have permission to upload payslip. Please contact
                    your administrator.
                  </Text>
                )}
              </Col>
            </Row>
          </Content>
        </Layout>
      </Spin>
    </div>
  );
};

export default SalaryUploadPage;
