import React, { useEffect, useState } from 'react';
import { Prompt, NavLink } from 'react-router-dom';
import dayjs from 'dayjs';
import { useForm } from 'antd/lib/form/Form';
import { Layout, Form, Row, Col, message, Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { FormInput, Text, Input, DatePicker, Select } from '../../components';
import InputNumber from '../../components/InputNumber';
import FormInputNew from '../../components/FormInputNew';
import DocumentUploader from '../../components/documentUploader';
import FileUploader from '../../components/FileUploader';
import TextSupporter from '../../components/textSupporter';
import ConfirmModal from '../../components/ConfirmModal';
import { parseRequestFormData } from '../../utils/parseRequestFormData';

// api
import { ClaimService, UserInfoService } from '../../services/api';
import { redirectTo } from '../../services/redirect';

const { Content } = Layout;

const BenefitClaimRequest = () => {
  const [form] = useForm();

  const [userId, setUserId] = useState(null);
  const [userInfoList, setUserInfoList] = useState([]);
  const [benefitClaimTypes, setBenefitClaimTypes] = useState([]);
  const [benefitClaimTypeRules, setBenefitClaimTypeRules] = useState([]);
  const [selectedBenefitClaimType, setSelectedBenefitClaimType] =
    useState(null);
  const [invoiceFile, setInvoiceFile] = useState(null);
  const [relatedDocumentFiles, setRelatedDocumentFiles] = useState([]);
  const [supportedText, setSupportedText] = useState('');
  const [relatedDocumentSubtitle, setRelatedDocumentSubtitle] = useState('');
  const [previousSelectedEmployee, setPreviousSelectedEmployee] =
    useState(null);
  const [previousSelectedClaimType, setPreviousSelectedClaimType] =
    useState(null);
  const [pendingChangedEmployee, setPendingChangedEmployee] = useState(null);
  const [pendingChangedClaimType, setPendingChangedClaimType] = useState(null);
  const [isSupportedTextVisible, setIsSupportedTextVisible] = useState(false);
  const [isEmployeeChangedModalOpen, setIsEmployeeChangedModalOpen] =
    useState(false);
  const [isClaimTypeChangedModalOpen, setIsClaimTypeChangedModalOpen] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    fetchUserInfos();
    fetchClaimTypeRules();
  }, []);

  useEffect(() => {
    setPreviousSelectedEmployee(form.getFieldValue('empCode'));
    fetchBenefitClaimTypes();
  }, [userId]);

  useEffect(() => {
    form.setFieldsValue({ invoice: invoiceFile });
  }, [invoiceFile]);

  useEffect(() => {
    form.setFieldsValue({
      relatedDocument: relatedDocumentFiles,
    });
  }, [relatedDocumentFiles]);

  useEffect(() => {
    setPreviousSelectedClaimType(form.getFieldValue('claimTypeId'));
  }, [selectedBenefitClaimType]);

  const fetchUserInfos = () => {
    UserInfoService.fetchUserInfoList(
      {},
      ({ data }) => {
        setUserInfoList(data);
        setIsLoading(false);
      },
      (response) => {
        if (response) {
          message.error(`Error: ${response.data.message}`);
        }
      },
    );
  };

  const fetchBenefitClaimTypes = () => {
    ClaimService.fetchClaimTypeList(
      { userId, isHrApplicable: true },
      ({ data }) => {
        setBenefitClaimTypes(data);
        setIsLoading(false);
      },
      (response) => {
        setIsLoading(false);
        if (response) {
          message.error(
            `Error while fetching benefit claim type: ${response.data.message}`,
          );
        }
      },
    );
  };

  const fetchClaimTypeRules = () => {
    ClaimService.fetchClaimTypeRules(
      ({ data }) => {
        setBenefitClaimTypeRules(data);
        setIsLoading(false);
      },
      (response) => {
        setIsLoading(false);
        if (response) {
          message.error(
            `Error while fetching claim type rules: ${response.data.message}`,
          );
        }
      },
    );
  };

  const onFinishBenefitClaimRequest = (value) => {
    // setIsLoading(true);
    delete value.empCode;
    delete value.invoice;
    delete value.relatedDocument;

    const formData = parseRequestFormData({ ...value, userId }, [
      ...(relatedDocumentFiles || []),
      ...(invoiceFile ? [invoiceFile] : []),
    ]);

    ClaimService.createClaimRequest(
      formData,
      () => {
        setIsLoading(false);
        message.success('Claim has been added successfully.', 0.5, () => {
          form.resetFields();
          setIsChanged(false);
          redirectTo('/claim');
        });
      },
      (response) => {
        setIsLoading(false);

        if (response) {
          message.error(`Error: ${response.data.message}`);
        }
      },
    );
  };

  const handleEmployeeChange = (value) => {
    const empCode = form.getFieldValue('empCode');
    const user = userInfoList.find((user) => user.empCode === empCode);

    if (!userId) {
      setUserId(user.userId);
    } else {
      setPendingChangedEmployee(value);
      setIsEmployeeChangedModalOpen(true);
    }
  };

  const handleClaimTypeChange = (value) => {
    if (!selectedBenefitClaimType) {
      applyClaimTypeChange(value);
    } else {
      setPendingChangedClaimType(value);
      setIsClaimTypeChangedModalOpen(true);
    }

    // validate case: select event date before selecting claim type
    if (form.getFieldValue('eventDate')) {
      form.validateFields(['eventDate']);
    }
  };

  const handleCloseEmployeeChangedModal = () => {
    form.setFieldsValue({ empCode: previousSelectedEmployee });
    setIsEmployeeChangedModalOpen(false);
    setPendingChangedEmployee(null);
  };

  const handleConfirmEmployeeChange = () => {
    setSelectedBenefitClaimType(null);
    setInvoiceFile(null);
    setRelatedDocumentFiles([]);
    setIsSupportedTextVisible(false);
    form.resetFields();
    form.setFieldsValue({ empCode: pendingChangedEmployee });
    setUserId(
      userInfoList.find((user) => user.empCode === pendingChangedEmployee)
        .userId,
    );
    setIsEmployeeChangedModalOpen(false);
  };

  const handleCloseClaimTypeChangedModal = () => {
    form.setFieldsValue({ claimTypeId: previousSelectedClaimType });
    form.validateFields(['claimTypeId']);
    setIsClaimTypeChangedModalOpen(false);
    setPendingChangedClaimType(null);
  };

  const handleConfirmClaimTypeChange = () => {
    setInvoiceFile(null);
    setRelatedDocumentFiles([]);
    setIsSupportedTextVisible(false);
    form.resetFields([
      'claimTypeId',
      'eventDate',
      'invoiceNumber',
      'amount',
      'description',
      'invoice',
      'relatedDocument',
    ]);
    form.setFieldsValue({ claimTypeId: pendingChangedClaimType });
    form.validateFields(['claimTypeId']);
    applyClaimTypeChange(pendingChangedClaimType);
    setIsClaimTypeChangedModalOpen(false);
  };

  const applyClaimTypeChange = (value) => {
    const selectedType = benefitClaimTypes.find(
      (record) => record.id === value,
    );
    const benefitClaimTypeRule = benefitClaimTypeRules.find(
      (record) => record.claimTypeId === value,
    );

    setSelectedBenefitClaimType(selectedType);
    setRelatedDocumentSubtitle(
      benefitClaimTypeRule?.relatedDocumentDescription.join(', '),
    );
    form.setFieldsValue({
      amount: selectedType.maxAmount ? selectedType.maxAmount : null,
    });

    setIsChanged(true);
  };

  return (
    <div style={{ width: 'auto' }}>
      <Prompt
        when={isChanged}
        message="You have unsaved changes. Are you sure you want to leave?"
      />
      <Layout style={{ minHeight: 'calc(100vh - 64px)' }}>
        <Content
          style={{
            margin: '32px 20px 0 20px',
            overflow: 'initial',
          }}
        >
          <Form
            style={{ width: '100%' }}
            form={form}
            onFinish={onFinishBenefitClaimRequest}
          >
            <Row gutter={[16, 16]}>
              {/* Header */}
              <Col span={24}>
                <Row
                  gutter={[16, 16]}
                  align="middle"
                  style={{ height: '100%' }}
                  wrap={false}
                >
                  <Col flex>
                    <NavLink
                      to={{ pathname: '/claim', state: { tabKey: '2' } }}
                      form="user-form"
                    >
                      <LeftOutlined className="text-normal" />
                    </NavLink>
                  </Col>

                  <Col
                    flex
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <Text h4 className="text-normal">
                      Request Benefit Claim
                    </Text>
                  </Col>
                </Row>
              </Col>

              {/* Body 1st card */}
              <Col span={24}>
                <Row className="card-container" gutter={[16, 16]}>
                  <Col span={24}>
                    <Text sub2 className="text-normal">
                      General Information
                    </Text>
                  </Col>

                  {/* Form fields for first card */}
                  {/* Employee No. */}
                  <Col xs={24} md={12}>
                    <FormInput
                      title="Employee No."
                      required
                      component={
                        <Form.Item
                          style={{ width: '100%', margin: '0' }}
                          name="empCode"
                          rules={[
                            {
                              required: true,
                              message: 'Please input employee no.',
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select employee no."
                            showSearch
                            optionFilterProp="children"
                            onChange={handleEmployeeChange}
                          >
                            {userInfoList.map((record) => (
                              <Select.Option
                                key={record.id}
                                value={record.empCode}
                              >
                                {`${record.empCode} - ${record.firstnameEn} ${record.lastnameEn}`}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      }
                    />
                  </Col>

                  <Col span={24}>
                    <Row gutter={[16, 16]}>
                      {/* Event Date */}
                      <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <FormInput
                          title="Event Date"
                          required
                          component={
                            <Form.Item
                              style={{ width: '100%', margin: '0' }}
                              name="eventDate"
                              rules={[
                                () => ({
                                  async validator(_, value) {
                                    if (!value) {
                                      return Promise.reject(
                                        'Please input event date.',
                                      );
                                    }

                                    if (!selectedBenefitClaimType) {
                                      return Promise.resolve();
                                    }

                                    setIsLoading(true);

                                    if (userId) {
                                      try {
                                        await new Promise((resolve, reject) => {
                                          ClaimService.validateCreateClaimRequest(
                                            selectedBenefitClaimType.id,
                                            {
                                              userId,
                                              targetField: 'eventDateField',
                                              eventDate: dayjs(value),
                                            },
                                            ({ data }) => {
                                              if (data.length > 0) {
                                                reject(data[0].params.message);
                                              } else {
                                                resolve();
                                              }
                                            },
                                            (response) => {
                                              reject(
                                                `Error while validating create claim request: ${response.data.message}`,
                                              );
                                            },
                                          );
                                        });

                                        setIsLoading(false);
                                        return Promise.resolve();
                                      } catch (error) {
                                        setIsLoading(false);
                                        return Promise.reject(error);
                                      }
                                    }
                                  },
                                }),
                              ]}
                            >
                              <DatePicker
                                format="DD/MM/YYYY"
                                disabledDate={(current) =>
                                  current > dayjs().endOf('year')
                                }
                                onChange={() => {
                                  setIsChanged(true);
                                }}
                              />
                            </Form.Item>
                          }
                        />
                      </Col>

                      {/* Claim Type */}
                      <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <FormInput
                          title="Type"
                          required
                          component={
                            <Form.Item
                              style={{ width: '100%', margin: '0' }}
                              name="claimTypeId"
                              rules={[
                                () => ({
                                  async validator(_, value) {
                                    if (!value) {
                                      return Promise.reject(
                                        'Please select benefit claim type.',
                                      );
                                    }

                                    if (!selectedBenefitClaimType) {
                                      return Promise.resolve();
                                    }

                                    setIsLoading(true);

                                    if (userId) {
                                      try {
                                        await new Promise((resolve, reject) => {
                                          ClaimService.validateCreateClaimRequest(
                                            selectedBenefitClaimType.id,
                                            {
                                              userId,
                                              targetField: 'claimTypeField',
                                            },
                                            ({ data }) => {
                                              if (
                                                data.length > 0 &&
                                                data[0].params.message
                                              ) {
                                                reject(data[0].params.message);
                                              } else {
                                                resolve();
                                              }
                                            },
                                            (response) => {
                                              reject(
                                                `Error while validating create claim request: ${response.data.message}`,
                                              );
                                            },
                                          );
                                        });
                                      } catch (error) {
                                        setIsLoading(false);
                                        return Promise.reject(error);
                                      }

                                      try {
                                        await new Promise((resolve, reject) => {
                                          ClaimService.validateCreateClaimRequest(
                                            selectedBenefitClaimType.id,
                                            {
                                              userId,
                                              targetField: 'supportText',
                                            },
                                            ({ data }) => {
                                              if (
                                                data.length > 0 &&
                                                data[0].params.message
                                              ) {
                                                setSupportedText(
                                                  data[0].params?.message,
                                                );
                                                setIsSupportedTextVisible(true);
                                              } else {
                                                setIsSupportedTextVisible(
                                                  false,
                                                );
                                                setSupportedText('');
                                              }
                                              resolve();
                                            },
                                            (response) => {
                                              reject(
                                                `Error while validating create claim request: ${response.data.message}`,
                                              );
                                            },
                                          );
                                        });

                                        setIsLoading(false);
                                        return Promise.resolve();
                                      } catch (error) {
                                        setIsLoading(false);
                                        return Promise.reject(error);
                                      }
                                    }
                                  },
                                }),
                              ]}
                            >
                              <Select
                                placeholder="Select type"
                                disabled={!userId}
                                onChange={handleClaimTypeChange}
                              >
                                {benefitClaimTypes.map((record, index) => (
                                  <Select.Option value={record.id} key={index}>
                                    {record.name}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          }
                        />
                      </Col>

                      {/* Invoice/Receipt Number */}
                      <Col xs={24} md={12}>
                        <FormInput
                          title="Invoice/Receipt Number"
                          required={selectedBenefitClaimType?.isInvoiceRequired}
                          component={
                            <Form.Item
                              style={{ width: '100%', margin: '0' }}
                              name="invoiceNumber"
                              rules={[
                                () => ({
                                  async validator(_, value) {
                                    if (
                                      selectedBenefitClaimType?.isInvoiceRequired
                                    ) {
                                      if (!value) {
                                        return Promise.reject(
                                          'Please input invoice/receipt number.',
                                        );
                                      }
                                    }

                                    return Promise.resolve();
                                  },
                                }),
                              ]}
                            >
                              <Input
                                placeholder="Invoice/Receipt Number"
                                disabled={
                                  !selectedBenefitClaimType?.isInvoiceRequired
                                }
                              />
                            </Form.Item>
                          }
                        />
                      </Col>

                      {/* Amount */}
                      <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <FormInput
                          title="Amount"
                          required
                          component={
                            <Form.Item
                              style={{ width: '100%', margin: '0' }}
                              name="amount"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input amount.',
                                },
                              ]}
                            >
                              <InputNumber
                                min={0}
                                placeholder="Enter amount"
                                disabled={selectedBenefitClaimType?.maxAmount}
                              />
                            </Form.Item>
                          }
                        />
                      </Col>

                      {/* Text support */}
                      <Col span={24}>
                        {isSupportedTextVisible && (
                          <TextSupporter
                            text={supportedText}
                            type={'info'}
                            showIcon={true}
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>

              {/* Body 2nd card */}
              <Col span={24}>
                <Row className="card-container" gutter={[16, 16]}>
                  <Col span={24}>
                    <Text sub2 className="text-normal">
                      Event Detail
                    </Text>
                  </Col>

                  {/* Form fields for second card */}
                  <Col span={24}>
                    <Row gutter={[16, 16]}>
                      {/* Reason & Detail */}
                      <Col span={24}>
                        <FormInput
                          title="Reason & Detail"
                          required
                          component={
                            <Form.Item
                              style={{ width: '100%', margin: '0' }}
                              name="description"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input reason & detail.',
                                },
                              ]}
                            >
                              <Input.TextArea
                                placeholder="Enter reason & detail"
                                rows={5}
                                onChange={() => {
                                  setIsChanged(true);
                                }}
                              />
                            </Form.Item>
                          }
                        />
                      </Col>

                      {/* Invoice file */}
                      {selectedBenefitClaimType?.isInvoiceRequired && (
                        <Col span={24}>
                          <FormInputNew
                            title={'Invoice File'}
                            required
                            component={
                              <Form.Item
                                style={{ width: '100%', margin: '0' }}
                                name="invoice"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please upload invoice file.',
                                  },
                                ]}
                              >
                                <FileUploader
                                  fileSizeLimit={5 * 1024 * 1024}
                                  fileType={
                                    'application/pdf, image/png, image/jpg, image/jpeg'
                                  }
                                  file={invoiceFile}
                                  setFile={setInvoiceFile}
                                  fieldName="invoiceFile"
                                />
                              </Form.Item>
                            }
                          />
                        </Col>
                      )}

                      {/* Related Documents (max 5 files)*/}
                      {selectedBenefitClaimType?.isRelatedDocumentRequired && (
                        <Col span={24}>
                          <FormInputNew
                            title={'Related Documents'}
                            subtitle={
                              relatedDocumentSubtitle
                                ? `(${relatedDocumentSubtitle})`
                                : null
                            }
                            required
                            component={
                              <Form.Item
                                style={{ width: '100%', margin: '0' }}
                                name="relatedDocument"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please upload related document.',
                                  },
                                ]}
                              >
                                <DocumentUploader
                                  fileSizeLimit={5 * 1024 * 1024}
                                  maxFileLimit={5}
                                  files={relatedDocumentFiles}
                                  setFiles={setRelatedDocumentFiles}
                                  fieldName="relatedDocumentFiles"
                                />
                              </Form.Item>
                            }
                          />
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
              </Col>

              {/* Footer buttons */}
              <Col span={24}>
                <Row gutter={[8, 8]} justify="end" align="middle">
                  <Col flex>
                    <NavLink
                      to={{ pathname: '/claim', state: { tabKey: '2' } }}
                    >
                      <Button width="96px" className="button-outlined">
                        Cancel
                      </Button>
                    </NavLink>
                  </Col>

                  <Col flex>
                    <Button
                      type="primary"
                      width="96px"
                      className="button-primary"
                      loading={isLoading}
                      onClick={() => form.submit()}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>

          {isEmployeeChangedModalOpen && (
            <ConfirmModal
              title={'Confirm Change Employee'}
              isModalOpen={isEmployeeChangedModalOpen}
              onClose={handleCloseEmployeeChangedModal}
              handleConfirm={handleConfirmEmployeeChange}
              children={
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Text body14 className="text-normal">
                    Are you sure you want to change the employee?
                  </Text>
                  <Text body14 className="text-normal">
                    All previous data will be lost after changing the employee.
                  </Text>
                </div>
              }
            />
          )}

          {isClaimTypeChangedModalOpen && (
            <ConfirmModal
              title={'Confirm Change Type'}
              isModalOpen={isClaimTypeChangedModalOpen}
              onClose={handleCloseClaimTypeChangedModal}
              handleConfirm={handleConfirmClaimTypeChange}
              children={
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Text body14 className="text-normal">
                    Are you sure you want to change the claim request type?
                  </Text>
                  <Text body14 className="text-normal">
                    All previous data will be lost after changing the type.
                  </Text>
                </div>
              }
            />
          )}
        </Content>
      </Layout>
    </div>
  );
};

export default BenefitClaimRequest;
