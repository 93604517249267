import React, { useContext, useEffect, useState } from 'react';
import { Layout } from 'antd';

import { ROLE_ID } from '../utils/constants';
import { AppContext } from '../components/context/AppContext';
import { Text } from '../components';
import Tabs from '../components/tabs';
import BenefitClaim from './employee/benefitClaim';
import BenefitClaimHr from './hr/BenefitClaimHr';
import MedicalClaimUpload from './hr/medicalClaimUpload';
import MedicalClaim from './medicalClaim';
import { useLocation } from 'react-router-dom';

const { Content } = Layout;

const Claim = () => {
  const { userState } = useContext(AppContext);
  const [tabKey, setTabKey] = useState('1');
  const location = useLocation();

  useEffect(() => {
    setTabKey(location?.state?.tabKey);
  }, []);

  const items = [
    {
      key: '1',
      label: <Text sub3>Medical Claim</Text>,
      children: <MedicalClaim />,
    },
    {
      key: '2',
      label: <Text sub3>Benefit Claim</Text>,
      children:
        userState?.currentRoleId === ROLE_ID.User ? (
          <BenefitClaim />
        ) : userState?.currentRoleId === ROLE_ID.Hr ||
          userState?.currentRoleId === ROLE_ID.HrManager ||
          userState?.currentRoleId === ROLE_ID.Admin ? (
          <BenefitClaimHr />
        ) : null,
    },
  ];

  return (
    <div style={{ width: 'auto' }}>
      <Layout style={{ minHeight: 'calc(100vh + 64px)' }}>
        <Content
          style={{
            margin: '32px 20px 0 20px',
            overflow: 'initial',
          }}
        >
          <Tabs
            defaultActiveKey="1"
            activeKey={tabKey}
            items={items}
            onTabClick={(key) => setTabKey(key)}
          />
        </Content>
      </Layout>
    </div>
  );
};

export default Claim;
