import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Layout, Row, message } from 'antd';
import { FeatureFlags } from '../components/context/FeatureFlagContext';
import { AppContext } from '../components/context/AppContext';
import moment from 'moment';
import { DatePicker, Select, Table, Tag, Text } from '../components';
import { EyeOutlined, FormOutlined, PlusOutlined } from '@ant-design/icons';
import { ImageService, LeaveService, ProfileService } from '../services/api';
import profileImage from '../assets/icons/profileimage.svg';
import AddLeaveRequestModal from '../components/leave/addLeaveRequestModal';
import LeaveRequestInformationModal from '../components/leave/leaveInformationModal';
import Search from 'antd/lib/input/Search';
import { formatDaysAndHours } from '../services/formatDate';
import { ReactComponent as AnnualLeaveIcon } from '../assets/icons/annualLeave.svg';
import { ReactComponent as PersonalLeaveIcon } from '../assets/icons/personalLeave.svg';
import { ReactComponent as SickLeaveIcon } from '../assets/icons/sickLeave.svg';

const { Content } = Layout;

var jwt = require('jsonwebtoken');
var token = sessionStorage.getItem('access-token');
var decode1 = jwt.decode(token);
const userId = decode1?.userId;

const Leave = () => {
  const { features } = useContext(FeatureFlags);
  const { userState } = useContext(AppContext);

  const [userInfo, setUserInfo] = useState();
  const [leaveTime, setLeaveTime] = useState();
  const [maxTime, setMaxTime] = useState();
  const [leaveData, setLeaveData] = useState();
  const [mainLeaveData, setMainLeaveData] = useState();
  const [isLoading, setIsLoading] = useState();
  const [year, setYear] = useState(moment().year());
  const [month, setMonth] = useState(moment().month() + 1);
  const [annual, setAnnual] = useState(0);
  const [personal, setPersonal] = useState(0);
  const [sick, setSick] = useState(0);
  const [leaveInformationModalVisible, setLeaveInformationModalVisible] =
    useState(false);
  const [addLeaveModalVisible, setAddLeaveModalVisible] = useState(false);
  const [leaveId, setLeaveId] = useState();
  const [leaveType, setLeaveType] = useState('All');
  const [search, setSearch] = useState('');

  useEffect(() => {
    fetchLeave();
    filterChange(search, leaveType);
  }, [userState, year, month]);

  const defaultStringCompare = (property) => (a, b) =>
    String(a[property]).localeCompare(String(b[property]));

  const fetchLeave = () => {
    const defaultLeaveTime = {
      cummulativeHourUse: 0,
      hourRemain: 0,
      maxHour: 0,
    };
    setIsLoading(true);

    //Fetch leave information for user
    if (userState.currentRoleId === 2) {
      LeaveService.getLeaveByYear(
        {
          year: year,
        },
        ({ data }) => {
          setIsLoading(false);
          setLeaveData(data.filter((leave) => leave.userId === userId));
          setMainLeaveData(data.filter((leave) => leave.userId === userId));
          setAnnual(data.filter((data) => data.leaveName == 'ANNUAL').length);
          setPersonal(
            data.filter((data) => data.leaveName == 'PERSONAL').length,
          );
          setSick(data.filter((data) => data.leaveName == 'SICK').length);
        },
        (response) => {
          setIsLoading(false);
          if (response && response.status === 400) {
            message.error(
              `Failed to fetch leave information - ${response.data.message}`,
            );
          } else {
            //message.error(response);
          }
        },
      );

      const params = {
        userId: userId,
        year: year,
      };

      LeaveService.getRemainByUserId(
        params,
        ({ data }) => {
          setLeaveTime({
            annual: data[0] ? data[0] : defaultLeaveTime,
            personal: data[1] ? data[1] : defaultLeaveTime,
            sick: data[2] ? data[2] : defaultLeaveTime,
          });
        },
        (response) => {
          setIsLoading(false);
          setLeaveTime(null);
          setMaxTime(null);
          if (response && response.status === 400) {
            message.error(
              `Failed to fetch leave remains - ${response.data.message}`,
            );
          }
        },
      );

      ProfileService.personalDetail.getPersonalDetail(
        userId,
        ({ data }) => {
          setUserInfo(data);
        },
        (response) => {
          setIsLoading(false);
          if (response && response.status === 400) {
            message.error(
              `Failed to fetch personal detail - ${response.data.message}`,
            );
          } else {
            message.error('Error');
          }
        },
      );
    } else {
      LeaveService.getLeave(
        {
          year: year,
        },
        ({ data }) => {
          setIsLoading(false);
          setLeaveData(data);
          setMainLeaveData(data);
          setAnnual(data.filter((data) => data.leaveName == 'ANNUAL').length);
          setPersonal(
            data.filter((data) => data.leaveName == 'PERSONAL').length,
          );
          setSick(data.filter((data) => data.leaveName == 'SICK').length);
        },
        (response) => {
          setIsLoading(false);
          if (response && response.status === 400) {
            message.error(
              `Failed to fetch leave data - ${response.data.message}`,
            );
          }
        },
      );
    }
  };

  const onTypeFilterChange = (value) => {
    setLeaveType(value);
    filterChange(search, value);
  };

  const onSearch = (value) => {
    setSearch(value);
    filterChange(value, leaveType);
  };

  const filterChange = (search, type) => {
    setLeaveData(
      mainLeaveData
        ?.filter((data) => {
          if (!search) {
            return true;
          } else {
            return (
              String(data.employeeName)
                .toUpperCase()
                .includes(search.toUpperCase()) ||
              String(data.employeeId)
                .toUpperCase()
                .includes(search.toUpperCase()) ||
              String(data.purpose).toUpperCase().includes(search.toUpperCase())
            );
          }
        })
        .filter((data) => {
          if (type != 'All') {
            return data.leaveStatus == type;
          } else {
            return true;
          }
        }),
    );
  };

  const openAddLeaveModal = () => {
    setAddLeaveModalVisible(true);
  };

  const closeAddLeaveModal = () => {
    setAddLeaveModalVisible(false);
  };

  const openLeaveRequestInformationModal = async (record) => {
    setLeaveId(record.leaveId);
    setLeaveInformationModalVisible(true);
  };

  const closeLeaveRequestInformationModal = () => {
    setLeaveId(null);
    setLeaveInformationModalVisible(false);
  };

  const columns = [
    {
      title: 'Doc No.',
      dataIndex: 'leaveId',
      width: '100px',
      fixed: 'left',
      sorter: (a, b) => a.leaveId - b.leaveId,
      render: (value, record) => (
        <Text small12 className="text-normal">
          LEV-{moment(record.createDatetime).format('YY')}
          {record.leaveId.toString().padStart(4, '0')}
        </Text>
      ),
    },
    {
      title: 'Emp No.',
      dataIndex: 'userId',
      width: '80px',
      fixed: 'left',
      sorter: (a, b) => a.employeeId - b.employeeId,
      render: (value, record) => (
        <Text small12 className="text-normal">
          {record.employeeId.toString().padStart(5, '0')}
        </Text>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'employeeName',
      width: '180px',
      sorter: defaultStringCompare('employeeName'),
      render: (name, record) => (
        <div>
          <div>
            <Text small12 className="text-normal">
              {record.employeeName}
            </Text>
          </div>
        </div>
      ),
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      width: '100px',
      sorter: defaultStringCompare('startDate'),
      render: (value) => (
        <Text small12 className="text-normal">
          {moment(value).format('DD/MM/YYYY')}
        </Text>
      ),
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      width: '100px',
      sorter: defaultStringCompare('endDate'),
      render: (value) => (
        <Text small12 className="text-normal">
          {moment(value).format('DD/MM/YYYY')}
        </Text>
      ),
    },
    {
      title: 'Total',
      dataIndex: 'leaveHour',
      width: '60px',
      render: (value) => (
        <Text small12 className="text-normal">
          {Math.floor(value / 8).toString()} :{' '}
          {Math.floor(value % 8).toString()}
        </Text>
      ),
    },
    {
      title: 'Leave Type',
      dataIndex: 'leaveName',
      width: '100px',
      sorter: defaultStringCompare('leaveName'),
      render: (value) => (
        <Text small12 className="text-normal">
          {value == 'ANNUAL'
            ? 'Annual Leave'
            : value == 'PERSONAL'
            ? 'Personal Leave'
            : 'Sick Leave'}
        </Text>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'leaveStatus',
      width: '160px',
      fixed: 'right',
      sorter: defaultStringCompare('leaveStatus'),
      render: (value) => (
        <Text small12 className="text-normal">
          {value == 'Pending' ? (
            <Tag yellow>Waiting For Approval</Tag>
          ) : value == 'Approved' ? (
            <Tag green>Approved</Tag>
          ) : value == 'Rejected' ? (
            <Tag red>Rejected</Tag>
          ) : value == 'Canceled by Approver' ? (
            <Tag grey>Canceled by Approver</Tag>
          ) : (
            <Tag grey>Canceled by User</Tag>
          )}
        </Text>
      ),
    },
    {
      title: 'Action',
      width: '60px',
      align: 'center',
      fixed: 'right',
      render: (key, record) => (
        <a onClick={() => openLeaveRequestInformationModal(record)}>
          {(record.leaveStatus == 'Pending' && record.supervisorId == userId) ||
          (record.leaveStatus == 'Approved' &&
            record.supervisorId == userId) ? (
            <FormOutlined className="text-primary-blue" />
          ) : (
            <EyeOutlined className="text-primary-blue" />
          )}
        </a>
      ),
    },
  ];

  const userColumns = [
    {
      title: 'Doc No.',
      dataIndex: 'leaveId',
      width: '120px',
      fixed: 'left',
      sorter: (a, b) => a.leaveId - b.leaveId,
      render: (value, record) => (
        <Text small12 className="text-normal">
          LEV-{moment(record.createDatetime).format('YY')}
          {record.leaveId.toString().padStart(4, '0')}
        </Text>
      ),
    },
    {
      title: 'Purpose',
      dataIndex: 'purpose',
      sorter: defaultStringCompare('purpose'),
      render: (name, record) => (
        <div>
          <Text small12 className="text-normal">
            {record.purpose}
          </Text>
        </div>
      ),
    },
    {
      title: 'Leave Type',
      dataIndex: 'leaveName',
      width: '100px',
      sorter: defaultStringCompare('leaveName'),
      render: (value) => (
        <Text small12 className="text-normal">
          {value == 'ANNUAL'
            ? 'Annual Leave'
            : value == 'PERSONAL'
            ? 'Personal Leave'
            : 'Sick Leave'}
        </Text>
      ),
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      width: '100px',
      sorter: defaultStringCompare('startDate'),
      render: (value) => (
        <Text small12 className="text-normal">
          {moment(value).format('DD/MM/YYYY')}
        </Text>
      ),
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      width: '100px',
      sorter: defaultStringCompare('endDate'),
      render: (value) => (
        <Text small12 className="text-normal">
          {moment(value).format('DD/MM/YYYY')}
        </Text>
      ),
    },
    {
      title: 'Total',
      dataIndex: 'leaveHour',
      width: '60px',
      render: (value) => (
        <Text small12 className="text-normal">
          {Math.floor(value / 8).toString()} :{' '}
          {Math.floor(value % 8).toString()}
        </Text>
      ),
    },

    {
      title: 'Status',
      dataIndex: 'leaveStatus',
      width: '180px',
      sorter: defaultStringCompare('leaveStatus'),
      render: (value) => (
        <Text small12 className="text-normal">
          {value == 'Pending' ? (
            <Tag yellow>Waiting For Approval</Tag>
          ) : value == 'Approved' ? (
            <Tag green>Approved</Tag>
          ) : value == 'Rejected' ? (
            <Tag red>Rejected</Tag>
          ) : value == 'Canceled by Approver' ? (
            <Tag grey>Canceled by Approver</Tag>
          ) : (
            <Tag grey>Canceled by User</Tag>
          )}
        </Text>
      ),
    },
    {
      title: 'Action',
      width: '60px',
      align: 'center',
      fixed: 'right',
      render: (key, record) => (
        <a onClick={() => openLeaveRequestInformationModal(key, record)}>
          {record.leaveStatus == 'Pending' ? (
            <FormOutlined className="text-primary-blue" />
          ) : (
            <EyeOutlined className="text-primary-blue" />
          )}
        </a>
      ),
    },
  ];

  return (
    <div style={{ width: 'auto' }}>
      <Layout style={{ minHeight: 'calc(100vh - 64px)' }}>
        <Content
          style={{
            margin: '32px 20px 0 20px',
            overflow: 'initial',
          }}
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Row gutter={[16, 16]}>
                <Col flex>
                  <Text h4 className="text-normal">
                    Leave Request
                  </Text>
                </Col>
                <Col flex="auto">
                  <Row gutter={[8, 8]} justify="end">
                    <Col flex>
                      <DatePicker
                        defaultValue={moment()}
                        picker="year"
                        format="YYYY"
                        disabledDate={(current) =>
                          current > moment().endOf('year')
                        }
                        allowClear={false}
                        onChange={(value) => {
                          setYear(moment(value).year());
                        }}
                      />
                    </Col>

                    <Col flex>
                      <Select
                        defaultValue={'All'}
                        style={{ width: '160px' }}
                        onChange={(value) => {
                          onTypeFilterChange(value);
                        }}
                      >
                        <Select.Option value="All">All Status</Select.Option>
                        <Select.Option value="Approved">Approved</Select.Option>
                        <Select.Option value="Pending">
                          Waiting For Approval
                        </Select.Option>
                        <Select.Option value="Rejected">Rejected</Select.Option>
                        <Select.Option value="Canceled by Approver">
                          Canceled by Approver
                        </Select.Option>
                        <Select.Option value="Canceled by User">
                          Canceled by User
                        </Select.Option>
                      </Select>
                    </Col>

                    <Col
                      flex="auto"
                      style={{
                        maxWidth: 320,
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Search
                        placeholder="Search"
                        onSearch={(value) => onSearch(value)}
                        allowClear={true}
                        style={{ width: '100%', maxWidth: 320 }}
                      />
                    </Col>

                    {userState?.currentRoleId === 2 && (
                      <Col flex>
                        <Button
                          type="primary"
                          width="96px"
                          className="button-primary"
                          onClick={() => openAddLeaveModal()}
                          icon={<PlusOutlined />}
                        >
                          Create Request
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </Col>
            {userState.currentRoleId === 2 && (
              <Col span={24}>
                <Row gutter={[16, 16]}>
                  <Col xs={24} md={8}>
                    <Row className="card-container" style={{ margin: 'auto' }}>
                      <Col
                        xs={24}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <AnnualLeaveIcon
                          fill="#F4364C"
                          style={{ marginRight: 5 }}
                        />
                        <Text small12 className="text-secondary-red">
                          {year} Annual Leave
                        </Text>
                      </Col>
                      <Col xs={24}>
                        {leaveTime ? (
                          <Row gutter={[16, 16]} justify="space-between">
                            <Col>
                              <Text sub4 className="text-normal">
                                Used:{' '}
                                {formatDaysAndHours(
                                  leaveTime
                                    ? leaveTime?.annual.cummulativeHourUse
                                    : 0,
                                )}
                              </Text>
                            </Col>
                            <Col>
                              <Text sub4 className="text-normal">
                                Remains:{' '}
                                <Text className="text-secondary-red">
                                  {formatDaysAndHours(
                                    leaveTime?.annual.hourRemain,
                                  )}
                                </Text>
                              </Text>
                            </Col>
                          </Row>
                        ) : (
                          <Text sub4 className="text-normal">
                            -
                          </Text>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} md={8}>
                    <Row className="card-container" style={{ margin: 'auto' }}>
                      <Col
                        xs={24}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <PersonalLeaveIcon
                          fill="#F4364C"
                          style={{ marginRight: 5 }}
                        />
                        <Text small12 className="text-secondary-red">
                          {year} Personal Leave
                        </Text>
                      </Col>
                      <Col xs={24}>
                        {leaveTime ? (
                          <Row gutter={[16, 16]} justify="space-between">
                            <Col>
                              <Text sub4 className="text-normal">
                                Used:{' '}
                                {formatDaysAndHours(
                                  leaveTime
                                    ? leaveTime?.personal.cummulativeHourUse
                                    : 0,
                                )}
                              </Text>
                            </Col>
                            <Col>
                              <Text sub4 className="text-normal">
                                Remains:{' '}
                                <Text className="text-secondary-red">
                                  {formatDaysAndHours(
                                    leaveTime?.personal.hourRemain,
                                  )}
                                </Text>
                              </Text>
                            </Col>
                          </Row>
                        ) : (
                          <Text sub4 className="text-normal">
                            -
                          </Text>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} md={8}>
                    <Row className="card-container" style={{ margin: 'auto' }}>
                      <Col
                        xs={24}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <SickLeaveIcon
                          fill="#F4364C"
                          style={{ marginRight: 5 }}
                        />
                        <Text small12 className="text-secondary-red">
                          {year} Sick Leave
                        </Text>
                      </Col>
                      <Col xs={24}>
                        {leaveTime ? (
                          <Row gutter={[16, 16]} justify="space-between">
                            <Col>
                              <Text sub4 className="text-normal">
                                Used:{' '}
                                {formatDaysAndHours(
                                  leaveTime
                                    ? leaveTime?.sick.cummulativeHourUse
                                    : 0,
                                )}
                              </Text>
                            </Col>
                            <Col>
                              <Text sub4 className="text-normal">
                                Remains:{' '}
                                <Text className="text-secondary-red">
                                  {formatDaysAndHours(
                                    leaveTime?.sick.hourRemain,
                                  )}
                                </Text>
                              </Text>
                            </Col>
                          </Row>
                        ) : (
                          <Text sub4 className="text-normal">
                            -
                          </Text>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            )}

            <Col span={24}>
              <Table
                columns={userState.currentRoleId === 2 ? userColumns : columns}
                scroll={{
                  x: 500,
                  y:
                    userState.currentRoleId === 2
                      ? 'calc(100vh - 330px)'
                      : 'calc(100vh - 260px)',
                }}
                dataSource={leaveData}
                rowKey="id"
                size="small"
                loading={isLoading}
                pagination={{
                  position: ['bottomRight'],
                  pageSizeOptions: [10, 20, 30, 40],
                  showSizeChanger: true,
                  defaultPageSize: 20,
                }}
              />
            </Col>
          </Row>
        </Content>
        <AddLeaveRequestModal
          addLeaveModalVisible={addLeaveModalVisible}
          closeAddLeaveModal={closeAddLeaveModal}
          onFetch={fetchLeave}
          leaveTime={leaveTime}
        />
        <LeaveRequestInformationModal
          leaveInformationModalVisible={leaveInformationModalVisible}
          modalClose={closeLeaveRequestInformationModal}
          leaveId={leaveId}
          onFetch={fetchLeave}
          userId={userId}
        />
      </Layout>
    </div>
  );
};

export default Leave;
