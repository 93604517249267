import {
  Row,
  Col,
  message,
  Button,
  Form,
  Layout,
  Upload,
  Tooltip,
  Alert,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { MedicalClaimService, UserInfoService } from '../../services/api';
import medicalClaimTemplate from '../../assets/files/medicalClaimTemplate.xlsx';

import {
  DeleteOutlined,
  DownloadOutlined,
  ExclamationCircleFilled,
  FileTextOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import { Text, Table } from '../../components';
import FormInputNew from '../../components/FormInputNew';
import { useForm } from 'antd/lib/form/Form';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import UploadIcon from '../../assets/icons/uploadIcon.svg';
import * as XLSX from 'xlsx';
import dayjs from 'dayjs';
import Search from 'antd/lib/input/Search';
import { filterBySearchValue } from '../../services/search';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

const { Content } = Layout;

const MedicalClaimUpload = () => {
  const [form] = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [medicalClaimPictureFile, setMedicalClaimPictureFile] = useState(null);
  const [mainClaimData, setMainClaimData] = useState([]);
  const [claimData, setClaimData] = useState([]);
  const [isCorrect, setCorrect] = useState(false);
  const [countError, setCountError] = useState(0);
  const history = useHistory();

  const fileSizeLimit = 5 * 1024 * 1024;

  useEffect(() => {
    form.setFieldsValue({
      medicalClaimPictureFile: medicalClaimPictureFile,
    });
  }, [medicalClaimPictureFile]);

  const toCamelCase = (str) => {
    return str
      .replace(/[^a-zA-Z0-9]+(.)/g, (match, chr) => chr.toUpperCase())
      .replace(/^[A-Z]/, (chr) => chr.toLowerCase());
  };

  const defaultStringCompare = (property) => (a, b) =>
    String(a[property]).localeCompare(String(b[property]));

  const defaultNumberCompare = (property) => (a, b) =>
    Number(a[property]) - Number(b[property]);

  const keyMapping = {
    'employeeNo.': 'empCode',
    'invoiceReceiptNo.': 'invoiceReceiptNo',
  };

  const fieldMapping = {
    empCode: 'Emp No.',
    treatmentDate: 'Treatment Date',
    amount: 'Amount',
    invoiceReceiptNo: 'Invoice/Receipt No.',
    hospitalReason: 'Hospital and Reason',
  };

  const downloadExcelTemplate = () => {
    const link = document.createElement('a');
    link.href = medicalClaimTemplate;
    link.download = 'medicalClaimTemplate.xlsx';
    link.click();
  };

  const columns = [
    {
      title: 'Emp No.',
      dataIndex: 'empCode',
      width: '80px',
      fixed: 'left',
      sorter: (a, b) => a.empCode - b.empCode,
      render: (value, record) => {
        let tooltipTitle = null;

        if (record.empCode && !record.isUser) {
          tooltipTitle = `Employee No. ${record.empCode} not found.`;
        } else if (record.nullField && record.nullField.length > 0) {
          tooltipTitle = `${record.nullField
            .filter((data) => fieldMapping[data])
            .map((data) => fieldMapping[data])
            .join(', ')} ${
            record.nullField.length > 1 ? 'fields are' : 'field is'
          } empty. All fields are required.`;
        } else if (!record.isValidDate) {
          tooltipTitle = 'Treatment Date value is invalid.';
        } else if (record.isDuplicate) {
          tooltipTitle = 'Duplicate Employee No.';
        } else if (record.isInvalidAmount) {
          tooltipTitle = 'Amount value is invalid.';
        } else if (!record.isEnoughClaimRemain) {
          const formattedValue = new Intl.NumberFormat().format(
            record.amountRemain,
          );
          tooltipTitle = `The imported amount exceeds the balance. The user's current balance is ${formattedValue}.`;
        }

        return (
          <span>
            <Text
              small12
              className="text-normal"
              style={{
                color:
                  !record.isUser ||
                  record.isDuplicate ||
                  (record.nullField && record.nullField.length > 0)
                    ? 'red'
                    : null,
              }}
            >
              {record.empCode}
            </Text>
            {tooltipTitle ? (
              <Tooltip title={tooltipTitle}>
                <ExclamationCircleFilled
                  style={{ color: 'red', fontSize: '12px', marginLeft: '8px' }}
                />
              </Tooltip>
            ) : null}
          </span>
        );
      },
    },
    {
      title: 'Emp Name',
      dataIndex: 'empName',
      width: '150px',
      fixed: 'left',
      sorter: defaultStringCompare('firstnameEn'),
      render: (value, record) => (
        <Text small12 className="text-normal">
          {record.firstnameEn && record.lastnameEn
            ? `${record.firstnameEn} ${record.lastnameEn}`
            : null}
        </Text>
      ),
    },
    {
      title: 'type',
      dataIndex: 'type',
      width: '80px',
      ellipsis: true,
      sorter: defaultStringCompare('firstnameEn'),
      render: (name, record) => (
        <Text small12 className="text-normal">
          ตรวจสุขภาพประจำปี
        </Text>
      ),
    },
    {
      title: 'Treatment Date',
      dataIndex: 'treatmentDate',
      width: '100px',
      ellipsis: {
        showTitle: false,
      },
      sorter: defaultStringCompare('treatmentDate'),
      render: (value, record) => (
        <Text
          small12
          className="text-normal"
          style={{
            color: !record.isValidDate ? 'red' : null,
          }}
        >
          {value}
        </Text>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      width: '80px',
      align: 'right',
      sorter: defaultNumberCompare('amount'),
      render: (value, record) => {
        const formattedValue =
          value !== null
            ? isNaN(value)
              ? value
              : new Intl.NumberFormat().format(value)
            : null;
        return (
          <Text
            small12
            className="text-normal"
            style={{
              color:
                record.isEnoughClaimRemain || !record.isInvalidAmount
                  ? null
                  : record.isUser
                  ? 'red'
                  : null,
            }}
          >
            {formattedValue}
          </Text>
        );
      },
    },
    {
      title: 'Invoice/Receipt No.',
      dataIndex: 'invoiceReceiptNo',
      width: '100px',
      align: 'right',
      ellipsis: {
        showTitle: false,
      },
      sorter: defaultStringCompare('invoiceReceiptNo'),
      render: (value) => (
        <Text small12 className="text-normal">
          {value}
        </Text>
      ),
    },
    {
      title: 'Hospital and Reason',
      dataIndex: 'hospitalReason',
      width: '130px',
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Text small12 className="text-normal">
          {value}
        </Text>
      ),
    },
  ];

  const fetchUserInfos = (ids, uploadedData) => {
    return new Promise((resolve, reject) => {
      UserInfoService.fetchUserInfoList(
        { empCode: ids },
        ({ data: fetchedData }) => {
          const updatedData = uploadedData.map((item) => {
            const userInfo = fetchedData.find(
              (info) => info.empCode === item.empCode,
            );
            return userInfo
              ? {
                  ...item,
                  firstnameEn: userInfo.firstnameEn,
                  lastnameEn: userInfo.lastnameEn,
                  nicknameEn: userInfo.nicknameEn,
                  email: userInfo.email,
                  userId: userInfo.userId,
                }
              : item;
          });
          resolve(updatedData);
        },
        (response) => {
          if (response) {
            message.error(`Error: ${response.data.message}`);
            reject(response);
          }
        },
      );
    });
  };

  const fetchMedicalClaimAmountRemain = (uploadedData) => {
    const validData = uploadedData.find(
      (data) => data.isValidDate && data.treatmentDate,
    );
    const treatmentYear = validData
      ? dayjs(validData.treatmentDate, 'DD/MM/YYYY').year()
      : dayjs().year();
    const param = {
      year: treatmentYear,
    };

    return new Promise((resolve, reject) => {
      MedicalClaimService.getMedicalClaimAmountRemainByYear(
        param,
        ({ data: claimData }) => {
          const updatedData = uploadedData.map((item) => {
            const matchedClaim = claimData.find(
              (claim) => claim.userId === item.userId,
            );
            return matchedClaim
              ? {
                  ...item,
                  amountRemain: matchedClaim.amountRemain,
                }
              : item;
          });
          resolve(updatedData);
        },
        (response) => {
          if (response) {
            message.error(`Error: ${response.data.message}`);
            reject(response);
          }
        },
      );
    });
  };

  const validateFileType = (file) => {
    const allowedTypes = ['.csv', '.xlsx'];
    const isValid = allowedTypes.some((type) => file.name.endsWith(type));

    if (!isValid) {
      message.error('Failed to upload file: Incorrect file format.');
    }

    return isValid || Upload.LIST_IGNORE;
  };

  const handleFileUpload = ({ file }) => {
    setIsLoading(true);

    if (file.size > fileSizeLimit) {
      message.error(
        `${file.name} is larger than ${
          fileSizeLimit / (1024 * 1024)
        } MB. Please select a smaller file.`,
      );
      setIsLoading(false);
      return;
    }

    const reader = new FileReader();

    reader.onload = async (e) => {
      const data = e.target.result;
      const isCSV = file.name.endsWith('.csv');
      const workbook = XLSX.read(data, {
        type: isCSV ? 'string' : 'binary',
        dateNF: ' MM/DD/YYYY',
      });

      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      let jsonData = XLSX.utils.sheet_to_json(sheet, { raw: false });

      if (jsonData.length <= 0) {
        setCorrect(false);
        message.error('Failed to upload file: Incorrect file format.');
        return;
      }

      jsonData = jsonData.map((row) => {
        Object.keys(row).forEach((key) => {
          const value = `${row[key]}`.trim();
          if (
            dayjs(value, 'M/D/YYYY', true).isValid() ||
            dayjs(value, 'MM/DD/YYYY', true).isValid()
          ) {
            row[key] = dayjs(value).format('MM/DD/YYYY');
          }
        });
        return row;
      });

      const empCodes = [];
      const empCount = {};

      const requiredFields = [
        'userId',
        'empCode',
        'treatmentDate',
        'amount',
        'invoiceReceiptNo',
        'hospitalReason',
      ];

      jsonData.forEach((row) => {
        const empNo = row['Employee No.'];
        empCount[empNo] = (empCount[empNo] || 0) + 1;
        if (!empCodes.includes(empNo)) {
          empCodes.push(empNo);
        }
      });

      jsonData = jsonData.map((row) => {
        const newRow = {};
        const empNo = row['Employee No.'];
        const nullFields = [];

        Object.keys(row).forEach((key) => {
          const camelKey = toCamelCase(key);
          const finalKey = keyMapping[camelKey] || camelKey;

          if (finalKey === 'treatmentDate') {
            const value = row[key];

            const isValidFormat = /^\d{2}\/\d{2}\/20\d{2}$/.test(value);
            newRow['isValidDate'] = isValidFormat;

            if (isValidFormat) {
              const treatmentDate = dayjs(value, 'MM/DD/YYYY', true);
              if (treatmentDate.isValid()) {
                newRow[finalKey] = treatmentDate.format('DD/MM/YYYY');
              } else {
                newRow[finalKey] = value;
              }
            } else {
              newRow[finalKey] = value;
            }
          } else {
            newRow[finalKey] = row[key];
          }
        });

        newRow.isDuplicate = empCount[empNo] > 1;

        requiredFields.forEach((field) => {
          if (!(field in newRow)) {
            newRow[field] = null;
            nullFields.push(field);
          }
        });

        newRow.nullField = nullFields;

        return newRow;
      });

      try {
        jsonData = await fetchUserInfos(empCodes, jsonData);
        jsonData = await fetchMedicalClaimAmountRemain(jsonData);

        const finalData = jsonData.map((row) => {
          row.isUser = Boolean(row.firstnameEn && row.lastnameEn && row.userId);
          if (!isNaN(row.amount)) {
            row.isEnoughClaimRemain =
              row.amountRemain - parseInt(row.amount) >= 0;
            row.isInvalidAmount = false;
          } else {
            row.isInvalidAmount = true;
          }
          const nullFields = requiredFields.filter(
            (field) => row[field] === null || row[field] === undefined,
          );
          row.nullField = nullFields;

          return row;
        });

        setCountError(
          finalData.reduce((count, data) => {
            if (
              !data.isUser ||
              !data.isEnoughClaimRemain ||
              data.nullField.length > 0 ||
              data.isDuplicate ||
              !data.isValidDate ||
              data.isInvalidAmount
            ) {
              count += 1;
            }
            return count;
          }, 0),
        );

        const isIncorrect = finalData.some(
          (data) =>
            !data.isUser ||
            !data.isEnoughClaimRemain ||
            data.nullField.length > 0 ||
            data.isDuplicate ||
            !data.isValidDate ||
            data.isInvalidAmount,
        );

        setCorrect(!isIncorrect);

        console.log('Formatted JSON Data:', finalData);
        setMainClaimData(finalData);
        setClaimData(finalData);
      } catch (error) {
        console.error('Error processing uploaded data:', error);
      }
    };

    reader.onerror = (error) => {
      console.error('Error reading file:', error);
    };

    if (file.type === 'text/csv') {
      reader.readAsText(file, 'UTF-8');
    } else {
      reader.readAsBinaryString(file);
    }

    setMedicalClaimPictureFile({
      fileName: 'medicalClaimUploadPictureFile',
      file,
    });

    setIsLoading(false);
  };

  const onSearch = (value) => {
    setClaimData(
      filterBySearchValue(mainClaimData || [], value, [
        'empCode',
        'fullnameEn',
        'nicknameEn',
        'email',
      ]),
    );
  };

  const handleFileDelete = () => {
    setMedicalClaimPictureFile(null);
    setClaimData([]);
    setMainClaimData([]);
    setCountError(0);
    setCorrect(false);
  };

  const onFinishMedicalClaimUpload = (value) => {
    const payload = mainClaimData.map((item) => ({
      medicalClaimTypeId: 3,
      amount: Number(item.amount),
      effectiveDate: dayjs(item.treatmentDate, 'DD/MM/YYYY').toDate(),
      referenceNumber: item.invoiceReceiptNo,
      reason: item.hospitalReason || undefined,
      userId: item.userId,
    }));
    setIsLoading(true);

    MedicalClaimService.uploadMedicalClaim(
      payload,
      ({ data }) => {
        setIsLoading(false);
        message.success('Medical claim upload has been created successfully.');
        setTimeout(() => {
          history.replace('/claim', { tabKey: '1' });
        }, 1000);
      },
      (response) => {
        setIsLoading(false);
        if (response) {
          message.error(
            `Failed to upload medical claim request: ${response.data.message}`,
          );
        }
      },
    );
  };

  return (
    <div style={{ width: 'auto' }}>
      <Layout style={{ minHeight: 'calc(100vh - 64px)' }}>
        <Content
          style={{
            margin: '32px 20px 20px 20px',
            overflow: 'initial',
          }}
        >
          <Form
            style={{ width: '100%' }}
            form={form}
            onFinish={onFinishMedicalClaimUpload}
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Row
                  gutter={[16, 16]}
                  align="middle"
                  style={{ height: '100%' }}
                  wrap={false}
                >
                  <Col flex>
                    <NavLink
                      to={{ pathname: '/claim', state: { tabKey: '1' } }}
                      form="user-form"
                    >
                      <LeftOutlined className="text-normal" />
                    </NavLink>
                  </Col>
                  <Col
                    flex
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <Text h4 className="text-normal">
                      Upload Medical Claim
                    </Text>
                  </Col>
                  <Col flex="auto" style={{ textAlign: 'right' }}>
                    <Button
                      width="96px"
                      className="button-outlined"
                      onClick={downloadExcelTemplate}
                      icon={<DownloadOutlined />}
                    >
                      Download Template
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row className="card-container" gutter={[16, 16]}>
                  <Col span={24}>
                    <Text sub2 className="text-normal">
                      Upload File
                    </Text>
                  </Col>
                  <Col span={24}>
                    <FormInputNew
                      title="Select File to Upload"
                      required
                      component={
                        <Form.Item
                          style={{ width: '100%', margin: '0' }}
                          name="medicalClaim"
                          rules={[
                            {
                              required: true,
                              message: 'Please upload medical claim',
                            },
                          ]}
                          extra={
                            <span
                              style={{ fontSize: '12px', color: '#52575C' }}
                            >
                              Please upload a .xlsx or a .csv file not exceeding
                              5MB.
                            </span>
                          }
                        >
                          {!medicalClaimPictureFile ? (
                            <Col xs={24} md={12}>
                              <Upload
                                showUploadList={false}
                                accept={'.csv, .xlsx'}
                                customRequest={handleFileUpload}
                                beforeUpload={validateFileType}
                              >
                                <Button
                                  type="link"
                                  icon={
                                    <img
                                      src={UploadIcon}
                                      width={16}
                                      height={16}
                                    />
                                  }
                                  style={{
                                    display: 'flex',
                                    float: 'left',
                                    padding: '0',
                                    gap: '8px',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Text sub3 className="text-normal">
                                    Upload File
                                  </Text>
                                </Button>
                              </Upload>
                            </Col>
                          ) : (
                            <Col xs={24} md={12}>
                              <Row className="card-container-bg-grey">
                                <Col
                                  xs={2}
                                  md={1}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'start',
                                  }}
                                >
                                  <FileTextOutlined />
                                </Col>

                                <Col
                                  xs={20}
                                  md={22}
                                  style={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  <Text body14 className="text-secondary-blue">
                                    {medicalClaimPictureFile.file.name}
                                  </Text>
                                </Col>

                                <Col
                                  xs={2}
                                  md={1}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'end',
                                  }}
                                >
                                  <DeleteOutlined
                                    onClick={handleFileDelete}
                                    className="text-secondary-red"
                                  />
                                </Col>
                              </Row>
                            </Col>
                          )}
                        </Form.Item>
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row className="card-container" gutter={[16, 16]}>
                  <Col span={24}>
                    <Text sub2 className="text-normal">
                      Preview Data
                    </Text>
                  </Col>
                  {countError > 0 && !isCorrect && (
                    <Col span={24}>
                      <Alert
                        message={`An error occurred in ${countError} ${
                          countError > 1 ? 'cases' : 'case'
                        }. Please review the data, correct the issues, and upload it again.`}
                        type="warning"
                        style={{
                          backgroundColor: '#F4364C14',
                        }}
                        className="custom-alert"
                        banner
                      />
                    </Col>
                  )}
                  <Col
                    flex="auto"
                    style={{
                      maxWidth: 320,
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Search
                      placeholder="Search"
                      onSearch={(value, event) => {
                        if (event) {
                          event.preventDefault();
                        }
                        onSearch(value);
                      }}
                      allowClear={true}
                      style={{ width: '100%', maxWidth: 320 }}
                    />
                  </Col>
                  <Col span={24}>
                    <Table
                      columns={columns}
                      scroll={{
                        x: 500,
                        y: 'calc(100vh - 260px)',
                      }}
                      dataSource={claimData}
                      rowKey="id"
                      size="small"
                      loading={isLoading}
                      pagination={{
                        position: ['bottomRight'],
                        pageSizeOptions: [10, 20, 30, 40],
                        showSizeChanger: true,
                        defaultPageSize: 20,
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={[8, 8]} justify="end" align="middle">
                  <Col flex>
                    <NavLink
                      to={{ pathname: '/claim', state: { tabKey: '1' } }}
                      form="user-form"
                    >
                      <Button width="96px" className="button-outlined">
                        Cancel
                      </Button>
                    </NavLink>
                  </Col>
                  <Col flex>
                    <Button
                      type="primary"
                      width="96px"
                      className="button-primary"
                      loading={isLoading}
                      onClick={() => {
                        form.submit();
                      }}
                      disabled={!isCorrect}
                    >
                      Upload
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Content>
      </Layout>
    </div>
  );
};

export default MedicalClaimUpload;
