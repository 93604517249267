export const filterBySearchValue = (data, value, fields) => {
  if (!value) {
    return data;
  }

  const uppercasedValue = value.toUpperCase();

  return data.filter((item) => {
    return fields.some((field) => {
      let fieldValue = '';

      if (field === 'fullnameEn') {
        fieldValue = `${item.firstnameEn} ${item.lastnameEn}`;
      } else {
        fieldValue = String(item[field] || '');
      }

      return fieldValue.toUpperCase().includes(uppercasedValue);
    });
  });
};
