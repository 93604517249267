import React, { useContext, useEffect, useState } from 'react';
import {
  DownloadOutlined,
  PlusOutlined,
  EyeFilled,
  CloseCircleOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Button, Col, Layout, Row, message } from 'antd';
import { NavLink } from 'react-router-dom';
import { decode } from 'jsonwebtoken';
import moment from 'moment';

import { FeatureFlags } from '../components/context/FeatureFlagContext';
import { AppContext } from '../components/context/AppContext';
import EditMedicalClaimModal from '../components/medicalClaim/editMedicalClaimModal';
import ExportMedicalClaimModal from '../components/medicalClaim/exportMedicalClaimModal';
import ConfirmModal from '../components/ConfirmModal';
import { Text, Tag, Select, DatePicker, Table } from '../components';

import { MedicalClaimService, ReportService } from '../services/api';

const { Content } = Layout;

const MedicalClaim = () => {
  const accessToken = sessionStorage.getItem('access-token');
  const userId = decode(accessToken).userId;

  const { features } = useContext(FeatureFlags);
  const { userState } = useContext(AppContext);

  const [data, setData] = useState();
  const [mainData, setMainData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isReportDownloading, setIsReportDownloading] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [exportModalVisible, setExportModalVisible] = useState(false);
  const [record, setRecord] = useState();
  const [remainingValue, setRemainingValue] = useState();
  const [usedValue, setUsedValue] = useState();
  const [year, setYear] = useState(moment().year());
  const [isCancelClaimRequestModalOpen, setIsCancelClaimRequestModalOpen] =
    useState(false);

  useEffect(() => {
    fetchMedicalClaimHistory();
  }, [userState, year]);

  const editModalClose = () => {
    setEditModalVisible(false);
    setRecord(null);
  };

  const editModalOpen = async (record) => {
    setEditModalVisible(true);
    setRecord(record);
  };

  const exportModalClose = () => {
    setExportModalVisible(false);
  };

  const onExportReport = async () => {
    setIsReportDownloading(true);
    const payload = {
      year: year,
    };
    await ReportService.fetchMedicalClaimReport(payload, (response) => {
      if (response) message.error(`Error: ${response.data.message}`);
    });
    setIsReportDownloading(false);
  };

  const fetchMedicalClaimHistory = () => {
    setIsLoading(true);
    if (userState.currentRoleId === 2) {
      const params = {
        year: year,
        userId: userId,
      };
      MedicalClaimService.getMedicalClaimHistoryByUserId(
        params,
        ({ data }) => {
          setData(data.medicalClaims);
          setMainData(data.medicalClaims);
          setIsLoading(false);
          setRemainingValue(
            data.medicalClaimRemains.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          );
          setUsedValue(
            data.medicalClaimUsed.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          );
        },
        (response) => {
          setIsLoading(false);
          if (response) message.error(`Error: ${response.data.message}`);
        },
      );
    } else {
      const params = {
        year: year,
      };
      MedicalClaimService.getAllMedicalClaimHistory(
        params,
        ({ data }) => {
          setData(data);
          setMainData(data);
          setIsLoading(false);
        },
        (response) => {
          setIsLoading(false);
          if (response) message.error(`Error: ${response.data.message}`);
        },
      );
    }
  };

  const onStatusTypeFilterChange = (value) => {
    if (value === 'All') {
      setData(mainData);
    } else {
      setData(
        mainData.filter((record) => record.requestStatusDisplay === value),
      );
    }
  };

  const onYearFilterChange = (value) => {
    if (value) {
      setYear(moment(value).year());
    }
  };

  const defaultStringCompare = (property) => (a, b) =>
    a[property].localeCompare(b[property]);

  const handleOpenCancelClaimRequestModal = (record) => {
    setRecord(record);
    setIsCancelClaimRequestModalOpen(true);
  };

  const handleCloseCancelClaimRequestModal = () => {
    setIsCancelClaimRequestModalOpen(false);
    setRecord(null);
  };

  const handleCancelClaimRequest = () => {
    setIsLoading(true);

    const payload = {
      requestStatusId: 400,
    };

    MedicalClaimService.updateMedicalClaimByUser(
      record.medicalClaimRequestId,
      payload,
      ({ data }) => {
        setIsLoading(false);
        message.success('Medical claim has been updated successfully.');
        setTimeout(() => {
          fetchMedicalClaimHistory();
        }, 1000);
      },
      (response) => {
        setIsLoading(false);
        if (response) {
          message.error(
            `Failed to update medical claim: ${response.data.message}`,
          );
        }
      },
    );

    setIsCancelClaimRequestModalOpen(false);
  };

  const columns = [
    {
      title: 'Claim No.',
      dataIndex: 'requestId',
      width: '100px',
      fixed: 'left',
      sorter: (a, b) => a.medicalClaimRequestId - b.medicalClaimRequestId,
      render: (value, record) => (
        <Text small12 className="text-normal">
          {record.runningNumber}
        </Text>
      ),
    },
    {
      title: 'Create Date',
      dataIndex: 'createDatetime',
      width: '100px',
      defaultSortOrder: 'descend',
      sorter: defaultStringCompare('createDatetime'),
      render: (value) => (
        <Text small12 className="text-normal">
          {moment(value).format('DD/MM/YYYY')}
        </Text>
      ),
    },
    userState.currentRoleId !== 2 && {
      title: 'Emp No.',
      dataIndex: 'employeeId',
      width: '80px',
      sorter: defaultStringCompare('employeeId'),
      render: (_, record) => (
        <Text small12 className="text-normal">
          {record?.employeeId}
        </Text>
      ),
    },
    userState.currentRoleId !== 2 && {
      title: 'Name',
      dataIndex: 'firstnameEn',
      width: '200px',
      sorter: defaultStringCompare('firstnameEn'),
      render: (key, record) => (
        <Text
          small12
          className="text-normal"
        >{`${record.firstnameEn} ${record.lastnameEn}`}</Text>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'medicalClaimName',
      width: '200px',
      sorter: defaultStringCompare('medicalClaimName'),
      render: (value) => (
        <Text small12 className="text-normal">
          {value}
        </Text>
      ),
    },
    {
      title: 'Treatment Date',
      dataIndex: 'effectiveDate',
      width: '130px',
      sorter: defaultStringCompare('effectiveDate'),
      render: (value) => (
        <Text small12 className="text-normal">
          {moment(value).format('DD/MM/YYYY')}
        </Text>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'amountUse',
      width: '80px',
      align: 'right',
      render: (value) => (
        <Text small12 className="text-normal">
          {value.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Text>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'requestStatusDisplay',
      width: '150px',
      fixed: 'right',
      sorter: defaultStringCompare('requestStatusDisplay'),
      render: (value) => {
        const valueLowerCased = value.toLowerCase();

        return (
          <Text small12 className="text-normal">
            {valueLowerCased === 'Pending'.toLowerCase() ? (
              <Tag yellow>Pending</Tag>
            ) : valueLowerCased === 'Approved'.toLowerCase() ? (
              <Tag green>Approved</Tag>
            ) : valueLowerCased === 'Rejected'.toLowerCase() ? (
              <Tag red>Rejected</Tag>
            ) : (
              valueLowerCased === 'Canceled'.toLowerCase() && (
                <Tag grey>Canceled</Tag>
              )
            )}
          </Text>
        );
      },
    },
    {
      title: 'Action',
      width: '60px',
      align: 'center',
      fixed: 'right',
      render: (key, record) => {
        if (
          record.requestStatusDisplay === 'Pending' &&
          userState.currentRoleId === 2
        ) {
          return (
            <div
              style={{ display: 'flex', justifyContent: 'center', gap: '16px' }}
            >
              <a
                onClick={() => {
                  editModalOpen(record);
                }}
              >
                <EyeFilled className="text-primary-blue" />
              </a>

              <a onClick={() => handleOpenCancelClaimRequestModal(record)}>
                <CloseCircleOutlined className="text-error" />
              </a>
            </div>
          );
        } else {
          return (
            <div
              style={{ display: 'flex', justifyContent: 'center', gap: '16px' }}
            >
              <a
                onClick={() => {
                  editModalOpen(record);
                }}
              >
                <EyeFilled className="text-primary-blue" />
              </a>

              <div style={{ width: '16px', height: '16px' }}></div>
            </div>
          );
        }
      },
    },
  ].filter(Boolean);

  return (
    <div style={{ width: 'auto' }}>
      <Layout style={{ minHeight: 'calc(100vh - 176px)' }}>
        <Content>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Row gutter={[16, 16]}>
                <Col flex>
                  <Text h4 className="text-normal">
                    Medical Claim
                  </Text>
                </Col>
                <Col flex="auto">
                  <Row gutter={[8, 8]} justify="end">
                    <Col flex>
                      <DatePicker
                        style={{ width: '160px' }}
                        defaultValue={moment()}
                        picker="year"
                        format="YYYY"
                        disabledDate={(current) =>
                          current > moment().endOf('year')
                        }
                        onChange={(value) => {
                          onYearFilterChange(value);
                        }}
                      />
                    </Col>
                    <Col flex>
                      <Select
                        defaultValue={'All'}
                        style={{ width: '160px' }}
                        onChange={onStatusTypeFilterChange}
                      >
                        <Select.Option value="All">All Status</Select.Option>
                        <Select.Option value="Approved">Approved</Select.Option>
                        <Select.Option value="Pending">Pending</Select.Option>
                        <Select.Option value="Rejected">Rejected</Select.Option>
                        <Select.Option value="Canceled">Canceled</Select.Option>
                      </Select>
                    </Col>
                    {userState?.currentRoleId === 2 && (
                      <Col>
                        <NavLink to={`/claim/medical/request`}>
                          <Button
                            type="primary"
                            width="96px"
                            className="button-primary"
                            icon={<PlusOutlined />}
                          >
                            Create Request
                          </Button>
                        </NavLink>
                      </Col>
                    )}
                    {features?.medicalClaimExportReport && (
                      <Col>
                        <Button
                          loading={isReportDownloading}
                          width="96px"
                          className="button-outlined"
                          onClick={onExportReport}
                          icon={<DownloadOutlined />}
                        >
                          Download
                        </Button>
                      </Col>
                    )}
                    {features?.medicalClaimUploadPage && (
                      <Col>
                        <NavLink to={`./claim/medical/upload/`}>
                          <Button
                            width="96px"
                            type="primary"
                            className="button-primary"
                            icon={<UploadOutlined />}
                          >
                            Upload
                          </Button>
                        </NavLink>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </Col>
            {userState.currentRoleId === 2 && (
              <Col span={24}>
                <Row gutter={[16, 16]}>
                  <Col xs={24} md={12}>
                    <Row className="card-container" style={{ margin: 'auto' }}>
                      <Col xs={24} span={1}>
                        <Text small12 className="text-secondary-red">
                          Remaining value in {year}
                        </Text>
                      </Col>
                      <Col xs={24}>
                        <Text sub4 className="text-normal">
                          {remainingValue} Baht
                        </Text>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} md={12}>
                    <Row className="card-container" style={{ margin: 'auto' }}>
                      <Col xs={24}>
                        <Text small12 className="text-secondary-red">
                          Used value in {year}
                        </Text>
                      </Col>
                      <Col xs={24}>
                        <Text sub4 className="text-normal">
                          {usedValue} Baht
                        </Text>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            )}

            <Col span={24}>
              <Table
                columns={columns}
                scroll={{
                  x: 300,
                  y:
                    userState.currentRoleId === 2
                      ? 'calc(100vh - 420px)'
                      : 'calc(100vh - 360px)',
                }}
                dataSource={data}
                size="small"
                loading={isLoading}
                pagination={{
                  position: ['bottomRight'],
                  pageSizeOptions: [10, 20, 30, 40],
                  showSizeChanger: true,
                  defaultPageSize: 20,
                }}
              />
            </Col>
          </Row>
        </Content>
      </Layout>
      <EditMedicalClaimModal
        open={editModalVisible}
        onCancel={editModalClose}
        onFetch={fetchMedicalClaimHistory}
        record={record}
      />
      {isCancelClaimRequestModalOpen && (
        <ConfirmModal
          title={'Confirm Cancel Request'}
          isModalOpen={isCancelClaimRequestModalOpen}
          onClose={handleCloseCancelClaimRequestModal}
          handleConfirm={handleCancelClaimRequest}
          children={
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text body14 className="text-normal">
                Are you sure you want to cancel this claim request?
              </Text>
              <Text body14 className="text-normal">
                Please check your information before proceeding.
              </Text>
            </div>
          }
        />
      )}

      <ExportMedicalClaimModal
        open={exportModalVisible}
        onCancel={exportModalClose}
      />
    </div>
  );
};
export default MedicalClaim;
